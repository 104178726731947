import { toast } from 'sonner';

import api from '@ux/api/api';
import cookies from '../cookies';

import { Cookie } from '../cookies/types';

api.defaults.baseURL = window.ENV.API_URL;

/**
 * Attaches the X-Organization-Id header if it already isn't attached.
 * For example, when calling the login method, the header is attached within the method
 */
api.interceptors.request.use((request) => {
  if (!request.headers['X-Organization-Id']) {
    request.headers['X-Organization-Id'] = cookies.get(Cookie.OrganizationPublicId);
  }
  return request;
});

// Request interceptor that attaches the access token to the Authorization header
api.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${cookies.get(Cookie.AccessToken)}`;
  return request;
});

// Response interceptor that shows a toast when error returns an unsuccessful response
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessage = error.response?.data?.error?.errors?.[0]; // The error message returned by the server

    toast.error(errorMessage ?? 'An error occured while contacting the server', { position: 'top-center' });
    return Promise.reject(error);
  },
);

export default api;
